import React from 'react'
import "./adress.scss"
export const Adress = () => {
    return (
        <>
            <section className="main-adress">
                <div className="my-container ">
                    <div className="row">
                        <div className="col-xl-8 col-md-12 m-auto p-0">
                            <div className='heading'>
                                <h2 className=''>Разблокируйте безграничный потенциал ИИ с Vectority</h2>
                                <p className=''>Готовы отправиться в путешествие к новым высотам? Инвестируйте во внутреннюю валюту экосистемы Vectority токен $Vector сегодня, что-бы получить доступ к миру возможностей искусственного интелекта. Присоединяйтесь к процветающему сообществу новаторов, инвесторов и футуристов, и получите доступ к эксклюзивным преимуществам и функциям нашего продукта. Не упустите шанс стать частью проекта который изменит мир.</p>
                                <a href='https://presale.vectority.ai/register'>
                                    <button className='addressbtn' >
                                    Купить $VECTOR
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className='lastimg-postn' data-aos="fade-right"
                                        // data-aos-offset="300"
                                        // data-aos-easing="ease-in-sine"
                                        data-aos-duration="2000">
                            <img
                                src="\poweredd.png"
                                className="img-fluid only-for-desttop"
                                alt="img"
                            />
                            <img src="/iintroductionss.svg" class=" img-fluid  only-for-mobileview" alt="img"></img>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Adress;
