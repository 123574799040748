import React, { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { API_URL } from "../../../utils/ApiUrl";
import "./navbar.scss";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWeb3React } from "@web3-react/core";
import useAuth from "../../../hooks/useAuth";
import useEthBalance from "../../../hooks/userBalance";
import axios from "axios";
import { useSelector } from "react-redux";
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import WOW from "wowjs"
const Copied = styled("span")`
  font-size: 16px;
  margin-left: 10px;
`;
const HOVER_TIMEOUT = 1000;

const Navbar = () => {

  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, [])

  const [toggleBtn, setToggleBtn] = useState(false)

  const users = useSelector((state) => state.UserReducer.GetUserAll);
  // console.log("user data", users);
  const { account } = useWeb3React();
  const [inputs, setInputs] = useState();
  const [copy, setCopy] = useState(false);
  // const [open, setOpen] = useState(false);
  const { login, logout } = useAuth();

  let ethBalance = useEthBalance();
  // console.log("ethBalance", ethBalance.toNumber() / 10**18)
  const balance = (ethBalance.toNumber() / 10 ** 18).toFixed(3)
  // console.log("balance of user", balance);

  const connectMetamask = () => {
    localStorage.setItem("connectorId", "injected");
    login("injected");
    localStorage.setItem("flag", "true");
    window.$("#exampleModal").modal("hide");
  };

  const trustWallet = async () => {
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout();
    } else {
      login("walletconnect");
      window.$("#exampleModal").modal("hide");
    }
  };

  const logout1 = () => {
    localStorage.setItem("flag", "false");
    // localStorage.clear();
    logout();
  };
  return (
    <section className="main-navbar-mobile main-navbar">
      <div className="container-fluid ">
        <div className="row">
          <div className="col-xl-11 col-lg-11 m-auto ">
            <nav className="navbar  navbar-expand-xl p-0">
              <a href="#" className="navbar-brand  wow bounce ">
                <Link to="/">
                  <img
                    src="\Spark-logo.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </Link>
              </a>
              <button
                className="navbar-toggler "
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle naNunitotion"
              >
                <span className="togg">
                  <i class="fas fa-bars">
                    <img
                      src="\togle.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </i>
                </span>
              </button>
              <div
                className="collapse navbar-collapse marg"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav m-auto">
                  <li className="nav-item active">
                    <Link to="/">
                      <HashLink to="#about-us" class="" style={
                        isActive("#aboutus")
                          ? {

                          }
                          : {}
                      }>
                        Про Vectority.AI
                      </HashLink>
                    </Link>
                  </li>
                  <li className="nav-item active">
                  <Link to="/">
                    <HashLink to="#how" class="" style={
                      isActive("#how")
                        ? {

                        }
                        : {}
                    }> Как это работает
                    </HashLink>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <HashLink to="#roadmap" class="" style={
                      isActive("#roadmap")
                        ? {

                        }
                        : {}
                    }>План проекта
                    </HashLink>
                  </li>
                  <li className="nav-item">
                    <HashLink to="#faqs" class="" style={
                      isActive("#faqs")
                        ? {

                        }
                        : {}
                    }>Часто задаваемые вопросы
                    </HashLink>
                  </li>


                </ul>


                <ul className=" right-btnandicons ">
                <a href="\vectorityAI.pdf" target="blank">
                  <li class="">
                    <button
                      class="btn button-hedaer"
                      type="button"
                    >
                      Читать Тех.Книгу
                    </button>
                  </li>
                  </a>
                  {/* <div>
                    <a href='https://t.me/vectorityai'>
                      <li class="icon-butns">
                      <img
                        src="\dns-logo\telegram-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      </li>
                    </a>
                  </div>
                  <div>
                    <a href='https://twitter.com/VectorityAI'>
                      <li class="icon-butns">
                        <img
                          src="\dns-logo\twit-icon.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </li>
                    </a>
                  </div> */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>

      {/* <div className="wallet-modal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div className="cross-icon">
                  <h3 class="modal-title text-center" id="exampleModalLabel">
                    Connect your Wallet
                  </h3>
                  <button
                    type="button"
                    class="close cross-img1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <img
                        src="\adress\cross-icon.svg"
                        className="img-fluid "
                      />
                    </span>
                  </button>
                </div>

                <div className="button-modal1 d-flex">
                  <button className="modal-button" onClick={connectMetamask}>
                    <img
                      src="\adress\metamask-icon.svg"
                      className="img-fluid"
                    />
                    <h3 className=""> MetaMask</h3>
                    <p className="">Connect to your MetaMask wallet </p>
                  </button>
                  <button className="modal-button" onClick={trustWallet}>
                    <img
                      src="\adress\walletconnect-icon.svg"
                      className="img-fluid"
                    />
                    <h3 className="">WalletConnect</h3>
                    <p className="">Connect to your MetaMask wallet </p>
                  </button>
                </div>
                <p className="text-center mt-3  connecting">
                  By connecting, I accept DNS{" "}
                  <span className="common connectinggg">Terms of Service </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </section>

  );
};

export default Navbar;
