import React, { useState, useEffect } from "react";
import "./statistic.scss";
import WOW from "wowjs";

const Statistic = () => {

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, [])

  return (
    <>
      <section className="main-Statistic ">
        <div className="my-container ">
            <div class=" postion-div">
                <div className="heading-power"data-aos="fade-down">
                <h3 className="">
                    ПРОГРЕСС ФАНДРАЙЗИНГА
                </h3>
                </div>
                {/* <div className="col-xl-7 col-12 m-auto "  
                data-aos="fade-right"
                data-aos-duration="1000"
                >
                <ProgressBar bgcolor={"#0ff"} completed={50}  only-for-desttop />
                </div> */}
                <div className="inner-img  ">
                    <img src="\progress_bar1812.svg" alt="" className="img-fluid" />
                </div>
                <br />
                <div className="inner-img  ">
                    <img src="\statistic1912.svg" alt="" className="img-fluid" />
                </div>
            </div>
          </div>
      </section>
    </>
  );
};

export default Statistic;
