import React, { useState, useEffect } from "react";
import "./generated.scss";
const Generated = () => {
  return (
    <>
      <section className="main-generated ">
        <div className="my-container ">

                <div className="row directionchange">
                  <div className="col-xl-7 col-12 m-auto "       data-aos="fade-right"
                                        // data-aos-offset="300"
                                        // data-aos-easing="ease-in-sine"
                                        data-aos-duration="2000">
                    <img
                      src="\building\Line.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <h2 className="allheading">
                      Генерация визуального контента
                    </h2>
                    <p>
                      Наш проект направлен на разработку и создание контента на основе ИИ сосредоточенного на работе с визуальными элементами. Используя передовые алгоритмы ИИ, включая распознавание изображений, перенос стиля и креативные техники, мы даем возможность бизнесам и независимым пользователям создавать контент исключительного качества с возможностью полностью контролировать процесс, с широким диапазоном требований к конечному результату и точной аналитикой текущих трендов различных отраслей. Давайте углубимся в ключевые аспекты нашей платформы для генерации визуального контента:
                    </p>
                  </div>
                  <div className="col-xl-5 col-12 "       data-aos="fade-left"
                                        // data-aos-offset="300"
                                        // data-aos-easing="ease-in-sine"
                                        data-aos-duration="2000">
                    <div className="lastimmgg-generated sm-margintopbtmm">
                      <img
                        src="\AI-Generated\generated.svg"
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                  </div>


                </div>
        
        </div>
      </section>
    </>
  );
};

export default Generated;
