import React, { useState, useEffect } from "react";
import "./automated.scss";
const Automated = () => {
  return (
    <>
      <section className="main-automated ">
        <div className="my-container ">
   
                <div className="row">
                  <div className="col-xl-5 col-12 "
                         data-aos="fade-right"
                         // data-aos-offset="300"
                         // data-aos-easing="ease-in-sine"
                         data-aos-duration="1000"
                  >
                    <div className="lastimmgg-automate sm-margintopbtmm">
                      <img
                        src="\automate\atomate.svg"
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-xl-7 col-12 m-auto "
                         data-aos="fade-left"
                         // data-aos-offset="300"
                         // data-aos-easing="ease-in-sine"
                         data-aos-duration="1000"
                  >
                    <img
                      src="\building\Line.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <h2 className="allheading">
                      Автоматическая генерация текста
                    </h2>
                    <p>
                    Наш проект направлен на разработку и создание контента на основе ИИ специально сосредоточеного на автоматической генерации текста. Используя продвинутый Natural Language Processing (NLP) и методы машинного обучения, мы расширяем возможности для бизнесов, издательств и независимых пользователей, для упрощения процесса создания и производства персонализированного, высококачественного текстового контента любых масштабов. Давайте рассмотрим Ключевые аспекты нашей платформы генерации текста с ИИ:
                    </p>
                  </div>

                </div>
              </div>
     
      </section>
    </>
  );
};

export default Automated;
