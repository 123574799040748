
import React, { useEffect, useState } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Banner = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  const [timeshow, settimeshow] = useState(false);
  // console.log("difference we get here is ", timeshow)
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  // var time = new Date(1690313745 * 1000)
  var time = new Date(2023, 11, 16, 0, 0, 0, 0)
  function timer() {
    var now = new Date()
    var diff = time.getTime() - now.getTime()
    if (diff <= 0) {
      settimeshow(true)
      return;
    }
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    var hours = Math.floor(diff / (1000 * 60 * 60));
    var mins = Math.floor(diff / (1000 * 60));
    var secs = Math.floor(diff / 1000);
    var d = days;
    var h = hours - days * 24;
    var m = mins - hours * 60;
    var s = secs - mins * 60;
    setDay(d);
    setHour(h);
    setMin(m);
    setSec(s)
  }
  setInterval(() => {
    timer()
  }, 1000);

  return (
    <>
      <section className="main-banner">

        <div className="my-container">
          <div class="row">
            <div class="col-xl-7 col-12 ">
              <div class="inner-content"
                data-aos="fade-right"
                // data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
              >
                <div class="inner-text">
                  <h1>Лидер маркетинговой индустрии на основе искусственного интеллекта</h1>
                  <h6>Генерация контента с помощью передовых ИИ технологий Vectority</h6>
                  <p>
                    Станьте частью проекта, который изменит целую индустрию. Наши алгоритмы предназначены для вывода процесса генерации контента на совершенно новый уровень. Vectority превратит глобальные творческие задачи в простую математику, где алгоритмы будут анализировать текущие реалии рынка и выполнять задачи, основываясь на полученных данных. Присоединяйтесь к нам, чтобы быть у истоков революционных изменений в отрасли искусственного интеллекта в сфере бизнеса и маркетинга.
                  </p>
                </div><div class="option-field">
                  {/* <img src="/main-banner/search-normal-input.svg" alt="img" class="img-fluid search-icon" />
                  <input pattern="[^\s]+" type="text" placeholder="Search name or addresses" value="" /> */}
                  {/* <a class="dgcvdvtdctcdvtvd" href=""> */}
                    <a href='https://presale.vectority.ai/register'>
                      <button class="btn-search">КУПИТЬ $VECTOR</button>
                    </a>
                  {/* </a> */}
                </div>
                {/* <div class="timer">
                  <div class="inner-text">
                        <h5>PRESALE IS ONGOING!</h5>
                  </div>
                </div> */}
                {/* <div class="timer">
                    <div class="timer-div">
                    <h6 class="timer-text">ЗАПУСК через: </h6>
                      <div class="inner-timer"> 
                        <h6>{day ? day : 0}</h6><p>ДНЕЙ</p></div><div class="inner-timer">
                        <h6>{hour ? hour : 0}</h6><p>ЧАСОВ</p></div><div class="inner-timer">
                        <h6>{min ? min : 0}</h6><p>МИНУТ</p></div><div class="inner-timer">
                        <h6>{sec ? sec : 0}</h6><p>СЕКУНД</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div class="col-xl-5 col-12   mt-md-5  ">
              <div class="banner-new-img " data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"

              >
                <img src="/main-banner/banner-img.svg" alt="img" class="img-fluid " /></div>
            </div>
          </div>

        </div>
      </section>
    </>
  );
};

export default Banner;
