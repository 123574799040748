import React, { useState, useEffect } from "react";
import "./introduction.scss";
const Introduction = () => {
  return (
    <>
      <section className="main-introduction ">
        <div className="my-container ">
          <div className="row directionchange">
            <div className="col-xl-7 col-12 m-auto " data-aos="fade-right"
              // data-aos-offset="300"
              // data-aos-easing="ease-in-sine"
              data-aos-duration="2000">
              <img
                src="\building\Line.svg"
                className="img-fluid"
                alt="img"
              />
              <h2 className="allheading">
                Знакомсвто с крипто токеном проекта
              </h2>
              <p>
                Наш проект представляет родной крипто токен $VECTOR , который служит фундаментальной валютой в экосистеме генерации контента. Токен действует как ключевой компонент, который облегчает беспрерывные взаимодействия и транзакции между участники любого масштаба, от предприятий и издательств, до независимых пользователей.
              </p>
            </div>
            <div className="col-xl-5 col-12 " data-aos="fade-left"
              // data-aos-offset="300"
              // data-aos-easing="ease-in-sine"
              data-aos-duration="2000">
              <div className="lastimmgg-generated sm-margintopbtmm">
                <img
                  src="\iintroductionss.svg"
                  className="img-fluid"
                  alt="img"
                />
              </div>
            </div>


          </div>
        </div>


      </section>
    </>
  );
};

export default Introduction;
