// import logo from './logo.svg';
import '../../App.scss';
import { useWeb3React } from "@web3-react/core";
import React, { useState, useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Giveaway from './giveaway/Giveaway.js';

// import Building from './building/Building ';

import Adress from './address/Adress';



import Faqs from './faqs/Faqs';

import Powerby from './powerby/Powerby';
import Automated from './automated/Automated';
import Generated from './generated/Generated';
import Multimedia from './multimedia/Multimedia';
import Howitwork from './howitwork/Howitwork';
import Tokenutility from './tokenutility/Tokenutility';
import Tokeneconomics from './tokeneconomics/Tokeneconomics';
import Introduction from './introduction/Introduction';
import Roadmap from './roadmap/Roadmap';
import About from './aboutus/About';
import Team from './team/Team';
import Statistic from './statistic/Statistic';
import Abouts from './about/About';
import WOW from "wowjs"


function Landing() {

  useEffect (() => {
    const wow = new WOW.WOW();
    wow.init();
  },[])

  return (
    <>
      <Banner />
      <Statistic />
      <Powerby />
      <Abouts />
      {/* <Building /> */}
      <Giveaway />
      <Automated />
      <Generated />
      <Multimedia />
      <Howitwork />
      <Introduction />
      <Tokenutility />
      <Tokeneconomics />
      <About />
      <Roadmap />
      <Team/>
      <Faqs />
      <Adress />
    </>
  );
}

export default Landing;