import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from "react-router-dom";
import './footer.scss';
const Footer = () => {
    const { hash } = useLocation();
    const isActive = (iHash) => hash === iHash;
    return (
        <>
            <section className="main-footer ">
                <div className="my-container ">
                    <div className="row">
                        <div className="col-xl-12  m-auto ">
                            <div className='last-footertop'>
                                <Link to="/">
                                    <img src="/Spark-logo.svg" alt="img" className="img-fluid footerimg" />
                                </Link>
                                <ul className="navbarnavmr-auto">
                                    <Link to="/">
                                        <HashLink to="#about-us" class="" style={
                                            isActive("#about-us")
                                            ? {}
                                            : {}
                                        }>
                                            <li className="nav-item ">
                                                Про Vectority.AI
                                            </li>
                                        </HashLink>
                                    </Link>
                                    <Link to="/">
                                        <HashLink to="#how" class="" style={
                                            isActive("#how")
                                            ? {}
                                            : {}
                                        }>
                                            <li className="nav-item ">
                                                Как это работает
                                            </li>
                                        </HashLink>
                                    </Link>
                                    <Link to="/">
                                        <HashLink to="#roadmap" class="" style={
                                            isActive("#roadmap")
                                            ? {}
                                            : {}
                                        }>
                                            <li className="nav-item ">
                                                План проекта
                                            </li>
                                        </HashLink>
                                    </Link>
                                    <Link to="/">
                                    <HashLink to="#faqs" class="" style={
                                            isActive("#faqs")
                                            ? {}
                                            : {}
                                        }>
                                            <li className="nav-item ">
                                                Часто задаваемые вопросы
                                            </li>
                                        </HashLink>
                                    </Link>

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            <section className="rights">
                <div className="my-container ">
                    <div className='row'>
                        <div className='col-xl-12 m-auto'>
                            <div className="inner-rightsscsd ">
                                <p className="sdgvsgdv">© 2023 Vectority<span className='common footerdns'></span></p>
                                {/* <p className='dvsgd'>
                                    <a href='/' target="_blank">
                                        <img src="\adress\icon-1.png" alt="img" className="img-fluid " />
                                    </a>
                                    <a href='/' target="_blank">
                                        <img src="\adress\icon2.png" alt="img" className="img-fluid " />
                                    </a>
                                    <a href='/' target="_blank">
                                        <img src="\adress\icon3.png" alt="img" className="img-fluid " />
                                    </a>
                                    <a href='/' target="_blank">
                                        <img src="\adress\icon2.png" alt="img" className="img-fluid " />
                                    </a>
                                    <a href='/' target="_blank">
                                        <img src="\adress\icon3.png" alt="img" className="img-fluid " />
                                    </a>
                                    <a href='/' target="_blank">
                                        <img src="\adress\icon2.png" alt="img" className="img-fluid " />
                                    </a>
                                    <a href='/' target="_blank">
                                        <img src="\adress\icon3.png" alt="img" className="img-fluid " />
                                    </a>
                                </p> */}
                                {/* <ul className="navbarnavmr-auto dvsgd">
                                    <a href='https://t.me/vectorityai'>
                                        <li class="icon-butns">
                                            <img
                                                src="\dns-logo\telegram-icon.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                        </li>
                                    </a>
                                    <a href='https://twitter.com/VectorityAI'>
                                        <li class="icon-butns">
                                            <img
                                                src="\dns-logo\twit-icon.svg"
                                                alt="img"
                                                className="img-fluid"
                                            />
                                        </li>
                                    </a>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer;
