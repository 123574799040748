import React from 'react';
import './tokeneconomics.scss';
const Tokeneconomics = () => {
    return (
        <>
            <section className="main-tokeneconomics">
                <div className="my-container">
                    <div className="row">
                        <div className="col-xl-12 m-auto">
                            <h2 className='howitswork'>
                                Экономика токена
                            </h2>
                            <p>Стоимость $VECTOR  подчиняется рыночным законам, обусловленным такими факторами, как спрос и предложение <br></br>динамика, принятие, использование платформы и в целом рост экосистемы. Наша модель токеномики <br></br>включает в себя механизмы для обеспечения устойчивой и сбалансированной системы:</p>
                            <div className="all-cards">
                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />

                                    <img src="/burn.svg" alt="img" className='img-fluid' />
                                    <h6>СЖИГАНИЕ ТОКЕНОВ</h6>
                                    <p>Плата за транзакции или доход от платформы используется для покупки и сжигания $VECTOR, сокращения циркуляции и потенциального повышение цены токена.</p>
                                </div>

                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />

                                    <img src="\crown.svg" alt="img" className='img-fluid' />
                                    <h6>СТЕЙКИНГ И УПРАВЛЕНИЕ</h6>
                                    <p>
                                        Владельцы токенов используют $VECTOR  для участия в управлении проектом, включая голосования по модернизации, политику и принципы функционирования, получение стимулов и наград.
                                    </p>
                                </div>

                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <img src="\nft.svg" alt="img" className='img-fluid' />
                                    <h6>ЗАМОРОЗКА ТОКЕНОВ</h6>
                                    <p>Чтобы способствовать стабильности и препятствовать крупным распродажам, резервы токенов что принадлежат членам команды и крупным партнерам будут заморожены на период до 2025 года.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tokeneconomics;
