import React, { useState, useEffect } from "react";
import "./about.scss";
import WOW from "wowjs";

const Abouts = () => {

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, [])

  return (
    <>
      <section className="main-aboutus" id='about-us'>
        <div className="my-container ">
            <div class=" postion-div">
                <div className="heading-power"data-aos="fade-down">
                <h3 className="">
                    Про Vectority.AI
                </h3>
                </div>
                <div className="video-responsive">
                  <iframe 
                    width="720" 
                    height="380" 
                    src="https://rutube.ru/play/embed/22bb132a3cc3c15b3fdb56cceafefb96" 
                    frameBorder="0" 
                    allow="clipboard-write; autoplay" 
                    webkitAllowFullScreen 
                    mozallowfullscreen 
                    allowFullScreen
                    title="Про Vectority.AI"
                    ></iframe>
                </div>
            </div>
          </div>
      </section>
    </>
  );
};

export default Abouts;
