import React, { useState } from "react";
import './App.scss';
import Landing from './components/landing/Landing.js';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Loader from "./hooks/loader";
import Navbar from './components/landing/header/Navbar.js';
import Footer from './components/landing/footer/Footer.js';
import useEagerConnect from './hooks/useEagerConnect'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {GetUsers} from './redux/action'
import {useDispatch} from 'react-redux'
import { useWeb3React } from "@web3-react/core";
import { useEffect } from 'react';

function App() {
  const { account } = useWeb3React();
  useEagerConnect()
  const dispatch=useDispatch();
  useEffect(() => {
    // setLoader(true)
    dispatch(GetUsers(account));
  }, [account]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
        <ToastContainer style={{ fontSize: 20 }} />
          <Router>
            <Navbar/>
            <Switch>
              <Route exact path='/' component={Landing} />
           
            </Switch>
            <Footer/>
          </Router>
    </>
  );
}

export default App;
