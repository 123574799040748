import React from 'react';
import './tokenutility.scss';
const Tokenutility = () => {
    return (
        <>
            <section className="main-tokenutility">
                <div className="my-container">
                    <div className="row">
                        <div className="col-xl-12 m-auto">
                            <h2 className='howitswork'>
                                РОЛЬ ТОКЕНА
                            </h2>
                            <p>
                                $VECTOR стимулирует важные внутренние процессы в экосистеме генерации контента Vectority, обеспечивая функциональность платформы <br></br>и предоставляет различные преимущества для держателей токена. Некоторые ключевые утилиты включают:
                            </p>
                            <div className="all-cards">
                                <div className="main-card">
                                       <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                
                                    <img src="/copywriter 1.svg" alt="img" className='img-fluid' />
                                    <h6>ПРОДВИНУТЫЙ ДОСТУП</h6>
                                    <p>
                                        Держатели токенов смогут использовать $VECTOR  для доступа к продвинутым функциям генерации контента, управляемых ИИ, включая шаблоны премиум класса. Это будет побуждать пользователей приобрести и удерживать $VECTOR для стабильности собственных результатов работы.
                                    </p>
                                </div>

                                <div className="main-card">
                                       <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    
                                    <img src="\settings (1) 1.svg" alt="img" className='img-fluid' />
                                    <h6>ПРЕМИАЛЬНЫЙ КОНТЕНТ</h6>
                                    <p>
                                        Владельцы токенов $VECTOR будут иметь эксклюзивный доступ к премиальному контенту, сгенерированному лучшими авторами и алгоритмами с правом на его первичное использование в собственных целях.
                                    </p>
                                </div>

                                <div className="main-card">
                                       <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <img src="\gift.svg" alt="img" className='img-fluid' />
                                    <h6>СИСТЕМА БОНУСОВ</h6>
                                    <p>
                                        Держатели $VECTOR и ранние инвесторы будут вознаграждатся различными бонусами в конце каждого финансового квартала. Так же будет осуществлена программа поощрения членов сообщества за вклад в развитие проекта.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Tokenutility;
