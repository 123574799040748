import React, { useState, useEffect } from "react";
import "./powerby.scss";
import AOS from "aos";
import "aos/dist/aos.css";
const Powerby = () => {
    
  // useEffect (() => {
  //   const wow = new WOW.WOW();
  //   wow.init();
  // },[])

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="main-powerby ">
        <div class="my-container ">
          <div class=" postion-div">
            <div className="heading-power"data-aos="fade-down">
              <h3 className="">
                Наши Партнеры
              </h3>
            </div>
            <div class="top-content">
              <button class="inner-content">
                <img src="\powerby\mail_ru.svg" alt="img" class="" />
              </button>
              <button class="inner-content">
                <img src="\powerby\ai_russia.svg" alt="img" class="" />
              </button>
              <button class="inner-content">
                <img src="\powerby\vk.svg" alt="img" class="" />
              </button>
              <button class="inner-content">
                <img src="\powerby\rutube.svg" alt="img" class=" py-2" />
              </button>
              <button class="inner-content">
                <img src="\powerby\kaspersky.svg" alt="img" class="" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Powerby;
