import React from 'react';
import './giveaway.scss';
const Giveaway = () => {
    return (
        <>
            <section className="main-give" id='features'>
                <div className="my-container">
                    <div className="row">
                        <div className="col-xl-12 m-auto">
                            <h2 className='howitswork'
                            data-aos="fade-down"
                            >
                                Функции
                            </h2>
                            <p>Разблокируйте весь потенциал ИИ в создании контента и поднимите вашу контент стратегию к новым высотам с нашей мультифункциональной платформой.</p>
                            <div className="all-cards">
                                <div className="main-card"
                                        data-aos="fade-right"
                                        // data-aos-offset="300"
                                        // data-aos-easing="ease-in-sine"
                                        data-aos-duration="1000"
                                >
                                       <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                
                                    <img src="\mint-cards\secrech-card.svg" alt="img" className='img-fluid' />
                                    <h6>АВТОМАТИЧЕСКОЕ СОЗДАНИЕ КОНТЕНТА</h6>
                                    <p>Упростите процесс создания контента с помощью расширенных алгоритмов ИИ, которые генерируют высококачественный, персонализированный контент в промышленных масштабах.</p>
                                </div>

                                <div className="main-card" data-aos="fade-up">
                                       <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    
                                    <img src="\mint-cards\Mint.svg" alt="img" className='img-fluid' />
                                    <h6>ПЕРСОНАЛИЗАЦИЯ В ЛУЧШЕМ ВИДЕ</h6>
                                    <p>Производите персонализированный продукт для вашей аудитории с помощью фильтров, настраиваемых на основе пользовательских предпочтений и поведении.</p>
                                </div>

                                <div className="main-card"
                                        data-aos="fade-left"
                                        // data-aos-offset="300"
                                        // data-aos-easing="ease-in-sine"
                                        data-aos-duration="1000"
                                >
                                       <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <img src="\mint-cards\manage.svg" alt="img" className='img-fluid' />
                                    <h6>ЭКОНОМИЯ ВРЕМЕНИ И РЕСУРСОВ</h6>
                                    <p>Сохраните ценное время и ресурсы путем автоматизации трудоемкого процесса создания контента и сосредоточьтесь на стратегических инициативах.</p>
                                </div>
                                <div className="main-card"
                                      data-aos="fade-right"
                                      // data-aos-offset="300"
                                      // data-aos-easing="ease-in-sine"
                                      data-aos-duration="1000"
                                >
                                       <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <img src="\mint-cards\analyzer.svg" alt="img" className='img-fluid' />
                                    <h6>АНАЛИТИКА</h6>
                                    <p>Используйте аналитику ИИ, чтобы получить ценную информацию о результативности контента, поведении пользователей и текущих тенденциях рынка, для принятия эффективных решений на основе полученных данных.</p>
                                </div>
                                <div className="main-card"data-aos="fade-down">
                                       <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <img src="\mint-cards\integration.svg" alt="img" className='img-fluid' />
                                    <h6>БЕСПЛАТНАЯ ИНТЕГРАЦИЯ</h6>
                                    <p>Интегрируйте алгоритмы Vectority в ваши существующие рабочие процессы и контент менеджмент для беспрепятственного бесплатного использования.</p>
                                </div>

                                <div className="main-card"       data-aos="fade-left"
                                        // data-aos-offset="300"
                                        // data-aos-easing="ease-in-sine"
                                        data-aos-duration="1000">
                                       <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <img src="\mint-cards\user-engagement.svg" alt="img" className='img-fluid' />
                                    <h6>УСОВЕРШЕНСТВОВАННОЕ ВЗАИМОДЕЙСТВИЕ</h6>
                                    <p>Создавайте захватывающий контент, который будет резонировать с вашей аудиторией, способствуя повышению взаимодействия и развитию длятельных связей.</p>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Giveaway;
