import React from 'react';
import './faqs.scss';
const Faqs = () => {
    return (
        <>
            <section className="main-faqs ptb" id='faqs'>
                <div className="my-container ">
                    <div className="row">
                        <div className="col-sm-12 p-0">
                            <div className="inner-content text-center">
                                {/* <h2 className="white ptb">FAQ</h2> */}
                                <h3 className="frequently">Часто задаваемые вопросы</h3>

                            </div>
                        </div>
                    </div>

                    <section className="main-faqs" id="faqs">
                        <div className="container p-0">

                            <div className="row">
                                <div className="col-sm-12 inner-upper">
                                    <div className="inner-side ptb20">
                                        <div id="accordion">
                                            <div className="card">

                                                <div id="collapseOne" className="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                                                    <div className="card-body">
                                                        We are a community of like-minded NFT collectors and artists through an all-in-one platform where they can easily interact, collect art and also get rewards. Long-term goal of our community is to build a metaverse where NFTs and Coins participate in the world. .
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingTwo">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            <h5 className=""><span className='font-weight-bold'>01. </span>  Что такое генерация контента на основе ИИ?</h5>
                                                            <p className='font-weight-bold'>+</p>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                    <div className="card-body">
                                                    Генерация контента на платформе Vectority происходит с использованием технологий и алгоритмов искусственного интеллекта (ИИ) для автоматизации и оптимизации процесса создания продукта. Этот процесс включает в себя: считывание и обработку речи, текста, изображений и видео в качестве пользовательских вводных данных, после чего с помощью машинного обучения алгоритм производит контент в указанной пользователем форме соответственно заданным параметрам.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingThree">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            <h5 className=""><span className='font-weight-bold'>02. </span>  Как ИИ улучшает создание контента?</h5>
                                                            <p className='font-weight-bold'>+</p>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                    <div className="card-body">
                                                        ИИ улучшает создание контента за счет автоматизации трудоемких задач, увеличивая эффективность и производительность, а также обеспечивая персонализированный подход к выполнению задач. Алгоритмы ИИ могут анализировать данные, идентифицировать шаблоны и генерировать высококачественный контент в соответствии с с запросами пользователей. Это позволяет предприятиям, издательствам и независимым создателям производить увлекательный, актуальный и индивидуализированный контент для своей аудитории.
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="card">
                                                <div className="card-header" id="headingfour">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsethree" aria-expanded="false" aria-controls="collapsethree">
                                                            <h5 className=""><span className='font-weight-bold'>03. </span> Vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</h5>
                                                            <p className='font-weight-bold'>+</p>
                                                        </button>
                                                    </h5>
                                                </div>
                                            
                                            </div> */}
                                            <div className="card">
                                                <div className="card-header" id="headingfive">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsefive" aria-expanded="false" aria-controls="collapsefour">
                                                            <h5 className=""><span className='font-weight-bold'>03. </span>  Каковы преимущества использования ИИ в создании контента?</h5>
                                                            <p className='font-weight-bold'>+</p>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapsefive" className="collapse" aria-labelledby="headingfour" data-parent="#accordion">
                                                    <div className="card-body">
                                                        Преимущества использования ИИ в создании контента включают повышенную эффективность и производительность, повышенное качество и актуальность, творческие возможности и инновации, масштабируемость, персонализацию и оптимизацию на основании аналитических данных. Создание контента с ИИ экономит время и ресурсы, а так же улучшает вовлечение аудитории.</div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingsix">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapsesix" aria-expanded="false" aria-controls="collapsefour">
                                                            <h5 className=""><span className='font-weight-bold'>04. </span> Какую роль в нашем проекте исполняет токен $VECTOR ?</h5>
                                                            <p className='font-weight-bold'>+</p>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapsesix" className="collapse" aria-labelledby="headingsix" data-parent="#accordion">
                                                    <div className="card-body">
                                                        Крипто токен $VECTOR  является неотъемлемой частью экосистемы проекта. Он исполняет роль топлива для осуществления и содержания процессов алгоритмов Vectority , а так же является внутренней валютой которая предоставляет доступ к расширенным функциям генерации контента и премиум возможностям. Кроме того, активные создатели контента вознаграждаются на основе токенов за участие в улучшении качества и популярности продуктов Vectority, что способствует процветанию нашего сообщества, стимулирует рост и активное участие, а значит и непрерывное развитие экосистемы Vectority.</div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingseven">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseseven" aria-expanded="false" aria-controls="collapsefour">
                                                            <h5 className=""><span className='font-weight-bold'>05.</span> Можно ли настроить и персонализировать процесс генерации ИИ контента?</h5>
                                                            <p className='font-weight-bold'>+</p>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseseven" className="collapse" aria-labelledby="headingseven" data-parent="#accordion">
                                                    <div className="card-body">
                                                    Да, генерация ИИ контента может осуществлятся по заданным параметрам пользователя. Предприятия и независимые создатели будут иметь возможность определять параметры, предпочтения и желаемые результаты для наших алгоритмов. Это позволит настраивать генерацию контента для соответствия с эстетикой бренда, целевой аудиторией и конкретными целями которые стоят перед пользователем. Создатели контента смогут полностью контролировать сгенерированный контент, а так же дополнительно уточнять и изменять его по мере необходимости.</div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header" id="headingeight5">
                                                    <h5 className="mb-0">
                                                        <button className="btn btn-link collapsed d-flex justify-content-between align-items-center" data-toggle="collapse" data-target="#collapseeight5" aria-expanded="false" aria-controls="collapseeight5">
                                                            <h5 className=""><span className='font-weight-bold'>06.</span> Обеспечена ли конфиденциальность и безопасность данных в процессе генерации контента с ИИ?</h5>
                                                            <p className='font-weight-bold'>+</p>
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div id="collapseeight5" className="collapse" aria-labelledby="headingeight5" data-parent="#accordion">
                                                    <div className="card-body white">
                                                        Да, конфиденциальность данных и безопасность имеют первостепенное значение в функционировании нашего продукта. Vectority  придерживается надежных мер конфиденциальности данных и следует соответствующим правила защиты данных и интелектуальной собственности. Пользовательские данные обрабатываются с осторожностью, и исключительно после конкретного согласия, полученого для сбора, обработки и хранения данных. Проект реализует протоколы безопасности для защиты информации пользователя и обеспечивает прозрачность в том, как эти данные используются на нашей платформе.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </div>
            </section>
        </>
    )
}

export default Faqs;
