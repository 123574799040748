import React, { useState, useEffect } from "react";
import "./multimedia.scss";
const Multimedia = () => {
  return (
    <>
      <section className="main-automated ">
        <div className="my-container ">
          <div className="row">
            <div className="col-xl-12 m-auto ">
              <div className="bannersix">
                <div className="row">
                  <div className="col-xl-5 col-12 p-0"  data-aos="fade-right"
                                        // data-aos-offset="300"
                                        // data-aos-easing="ease-in-sine"
                                        data-aos-duration="2000">
                    <div className="lastimmgg-automate sm-margintopbtmm">
                      <img
                        src="/multi.svg"
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="col-xl-7 col-12 m-auto p-0"  data-aos="fade-left"
                                        // data-aos-offset="300"
                                        // data-aos-easing="ease-in-sine"
                                        data-aos-duration="2000">
                    <img
                      src="\building\Line.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <h2 className="allheading">
                    Генерация мультимедиа контента
                    </h2>
                    <p>
                      Наш проект направлен на создание платформы для создания контента с ИИ, которая не ограничивается только генерацией текста. Так же, наш продукт выполняет задачи по созданию мультимедийного контента. Используя ИИ алгоритмы и техники, целые предприятия или независимые пользователи могут автоматизировать производство разнообразных и привлекательных форматов контента, включая видео, аудио и интерактивные элементы. Давайте рассмотрим ключевые аспекты генерации мультимедиа контента на основе ИИ:
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Multimedia;
