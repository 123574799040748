import React from 'react';
import './team.scss';
const Team = () => {
    return (
        <>
            <section className="main-Team" id='how'>
                <div className="my-container">
                    <div className="row">
                        <div className="col-xl-12 m-auto">
                            <h2 className='howitswork'>
                                Наша команда
                            </h2>
                            <div className="all-cards set-all-cards">
                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <div className='topimgg'>
                                        <img src="\ourteam\fst.svg" alt="img" className='img-fluid' />
                                    </div>
                                    <div className='outerdiv'>
                                        <h6 className='nameheading'>Илья Суцкевер</h6>
                                        <p className='ceopara'>
                                            Генеральный директор
                                        </p>
                                        <p className='ceopara only_padng'>
                                            Илья - выдающийся ученый в сфере IT технологий, специализирующийся на машинном обучении. Он является соочредителем компании OpenAI, где ранее исполнял обязанности директора технического отдела. Одним из продуктов OpenAI является всем извесный чат GPT.</p>
                                    </div>
                                </div>

                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <div className='topimgg'>
                                        <img src="\ourteam\sec.svg" alt="img" className='img-fluid' />
                                    </div>
                                  <div className='outerdiv'>
                                        <h6 className='nameheading'>Михаил Овечкин</h6>
                                        <p className='ceopara'>
                                            Технический директор
                                        </p>
                                        <p className='ceopara only_padng'>
                                            Михаил стоит у истоков зарождения блокчейн технологий. Его опыт разработки блокчейн архитектуры играет ключевую роль в управлении технических процессов проекта.
                                        </p>
                                    </div>
                                </div>
                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <div className='topimgg'>
                                        <img src="\ourteam\thrd.svg" alt="img" className='img-fluid' />
                                    </div>
                                  <div className='outerdiv'>
                                        <h6 className='nameheading'>Елизавета Омович</h6>
                                        <p className='ceopara'>
                                            Глава отдела маркетинга
                                        </p>
                                        <p className='ceopara only_padng'>
                                            С опытом работы в технологических стартапах и врожденной способностью для стратегичесого расскрытия историй, Елизавета возглавляет глобальные маркетинговые инициативы проекта, обеспечивая эффективное привлечение пользователей и популяризацию бренда.
                                        </p>
                                    </div>
                                </div>
                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <div className='topimgg'>
                                        <img src="\ourteam\four.svg" alt="img" className='img-fluid' />
                                    </div>
                                  <div className='outerdiv'>
                                        <h6 className='nameheading'>Дмитрий Исаев</h6>
                                        <p className='ceopara'>
                                            Ведущий разработчик блокчейна
                                        </p>
                                        <p className='ceopara only_padng'>
                                            Опытный разработчик Дмитрий был ключевым в различных блокчейн проектах Европы и Запада. Его глубокая экспертиза кодирования гарантирует техническую надежность проекта, стабильность и масштабируемость.
                                        </p>
                                    </div>
                                </div>

                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <div className='topimgg'>
                                        <img src="\ourteam\five.svg" alt="img" className='img-fluid' />
                                    </div>
                                  <div className='outerdiv'>
                                        <h6 className='nameheading'>Сергей Ван</h6>
                                        <p className='ceopara'>
                                            Менеджер сообщества
                                        </p>
                                        <p className='ceopara only_padng'>
                                            Сергей превосходен в выстраивании и развитии связей внутри сообществ. Он гарантирует, что пользовательское сообщество Vectority будет вовлеченным, информированным на протяжении всего путешествия проекта.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Team;
