import React from 'react';
import './howitwork.scss';
const Howitwork = () => {
    return (
        <>
            <section className="main-howitwork" id='how'>
                <div className="my-container">
                    <div className="row">
                        <div className="col-xl-12 m-auto">
                            <h2 className='howitswork'>
                                Как это работает
                            </h2>
                            {/* <p>Unlock the full potential of AI in content creation and elevate your content strategy to new heights with our feature-rich platform.</p> */}
                            <div className="all-cards set-all-cards">

                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <div className='topimgg'>
                                        <img src="\howitwork\neurolinguistic-programation.svg" alt="img" className='img-fluid' />
                                        <h5 className=''>1</h5>
                                    </div>
                                    <h6>ПЕРСОНАЛИЗАЦИЯ В ЛУЧШЕМ ВИДЕ</h6>
                                    <p>
                                        Разблокируйте силу индивидуального опыта использования на нашей персонализированной ИИ платформе. Взаимодействуйте со своей аудиторией на более глубоком уровне и предоставляйте контент, который резонирует с их уникальными предпочтениями и интересами. Улучшайте коммуникацию, выстраивайте лояльность для более эффективного взаимодействия с потребителем.
                                    </p>
                                </div>

                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <div className='topimgg'>
                                        <img src="/howitwork/image.svg" alt="img" className='img-fluid' />
                                        <h5 className=''>2</h5>
                                    </div>
                                    <h6>РАСПОЗНАВАНИЕ ИЗОБРАЖЕНИЙ</h6>
                                    <p>
                                        Улучшайте точность визуальных эффектов с помощью современной технологии распознавания изображений. Наша платформа с ИИ анализирует и совершенствует изображения, позволяя вам создавать потрясающий визуальный продукт, который очарует вашу аудиторию. Наш алгоритм работы с изображениями позволит вам выделиться из толпы и оставить незабываемые впечатления перед потребителем.
                                    </p>
                                </div>
                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <div className='topimgg'>
                                        <img src="/howitwork/data-analytics.svg" alt="img" className='img-fluid' />
                                        <h5 className=''>3</h5>
                                    </div>
                                    <h6>АНАЛИЗ ДАННЫХ И РАСПОЗНАВАНИЕ ПАТТЕРНОВ</h6>
                                    <p>
                                        Получите ценные данные и принимайте решения, на основе информации, собранной искусственным интеллектом по вашему запросу. Наши продвинутые алгоритмы распознавания паттернов, трендов и корреляций позволят вам оптимизировать стратегии работы, для наиболее точного взаимодейстивя с аудиторией и эффективных результатов.
                                    </p>
                                </div>

                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <div className='topimgg'>
                                        <img src="\howitwork\text-box.svg" alt="img" className='img-fluid' />
                                        <h5 className=''>4</h5>
                                    </div>
                                    <h6>ГЕНЕРАЦИЯ ТЕКСТА И ОПТИМИЗАЦИЯ</h6>
                                    <p>
                                        Попрощайтесь с механичным созданием текстового контента. С помощью нашей технологии генерации текста управляемой ИИ этот процесс больше не требует грандиозных усилий. Алгоритмы Vectority нацелены на создание высококачественного письменного контента - от персонализированных сообщений и кратких резюме, до больших художественных эссе или технических документов, наша платформа оптимизирует контент и процесс его создания, экономя время и обеспечивая первоклассное качество.
                                    </p>
                                </div>

                                <div className="main-card">
                                    <img src="\mint-cards\Line.svg" alt="img" className='img-fluid position-absulute ' />
                                    <div className='topimgg'>
                                        <img src="\howitwork\five.svg" alt="img" className='img-fluid' />
                                        <h5 className=''>5</h5>
                                    </div>
                                    <h6>ГЕНЕРАЦИЯ ВИДЕО И МУЛЬТИМЕДИА</h6>
                                    <p>
                                        Преображайте свой мультимедиа контент с помощью наших ИИ алгоритмов, которые откроют перед вами неограниченые возможности. Создавайте захватывающие видео, аудио и интерактивные мультимедиа высокого качества, котороык будут вовлекать и восхищать вашу аудиторию. С нашей платформой вы можете реализовать свой творческий потенциал, улучшить узнаваемость бренда, поместив свой контент на следующий уровень качества.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Howitwork;
