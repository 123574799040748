import React from 'react';
import './about.scss';
const About = () => {
    return (
        <>
            <section className="main-about ">
                <div className="my-container">
                    <div className='row'>
                        <div className='col-sm-12 text-center'>
                            <h2 className=' headng-istribution'  data-aos="flip-right"
                                data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000"> 
                                Дистрибуция Токена</h2>
                            <h2 className='stextupply'>Общее колличество: <span className='common'>700,000,000 </span></h2>
                            <h2 className='stextupply'>Цена предпродажи: <span className='common'>0.15$ </span></h2>
                            <div className="inner-img  " data-aos="flip-left"
                                data-aos-easing="ease-out-cubic"
                                data-aos-duration="3000">
                                <img src="\tokendistribution.svg" alt="" className="img-fluid " />
                                {/* only-for-desttop  */}
                                {/* <img src="\tokendistributionmobile.svg" alt="" className="img-fluid only-for-mobileview my-5" />
                                <img src="\tokendistributionmobiletext.svg" alt="" className="img-fluid only-for-mobileview mt-5" /> */}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default About;
