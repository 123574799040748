import React from 'react';
import './roadmap.scss';
const Roadmap = () => {
    return (
        <>
            <section className="roadmap-section ptb20" id='roadmap'>
                <div className="my-container">
                    <div className="main-heading">
                        <h6>План проекта</h6>
                    </div>
                    <div className="roadmap-set inner-add-text-desktop">
                        <img src="\roadmap-desktop.svg" alt="img" className='img-fluid' />
                        <div className="inner-add-text">
                            <div className="text-item text-item1">
                                <h6> Квартал 3 </h6>
                                <ul>
                                    <li>Запуск проекта: официальный запуск проекта Vectority.ai, платформы по генерации контента, основанной на ИИ технологиях.</li>
                                    <li>Генерация и распределение токенов: старт приватных и публичных раундов предварительной продажи токенов, Распределение между крупными партнерами-инвесторами и независимыми членами сообщества.</li>
                                    <li>Тестовая интеграция в бизнес процессы партнеров: Старт приватного бета периода работы алгоритмов Vectority в ведущих компаниях СНГ и Запада.</li>
                                    <li>Запуск платформы: команда разработчиков фокусируется на совершенствовании основных функций платформы, включая алгоритмы генерации контента на основе искусственного интеллекта, пользовательский интерфейс, функционирование экосистемы проэкта и механизмы интеграции продукта в бизнес процессы.</li>
                                </ul>
                            </div>
                            <div className="text-item text-item2">
                                <h6> Квартал 4</h6>
                                <ul>
                                    <li>Бета версия платформы в открытом доступе: бета версия платформы запускается, приглашая ограниченное количество пользователей для предоставления обратной связи и проверки возможностей платформы в масштабах комьюнити.</li>
                                    <li>Развитие сообщества: акцент делается на развитие сообщества с помощью маркетинга и кампаний по повышению осведомленности, взаимодействия с потенциальными пользователями и адаптации процесса персонализированного контентмейкинга.</li>
                                    <li>Интерактивные улучшения: на основе результатов и отзывов пользователей и тестирования. Команда разработчиков совершенствует на функции платформы, улучшая пользовательский опыт работы с продуктом.</li>
                                    <li>Листинг на биржах: Vectority выходит на рынок на Binance, Kucoin и Crypto.com.</li>
                                </ul>
                            </div>
                            <div className="text-item text-item3">
                                <h6> Квартал 1</h6>
                                <ul>
                                    <li>Публичный запуск платформы: платформа доступна для широкой пользовательской базы. Пользователи могут начать создавать и генерировать контент, используя доступные инструменты и функции ИИ.</li>
                                    <li>Система бонусов для пользователей: внедрение токеновых вознаграждений пользователей для стимулирования высококачественноого производства и взаимодействия.</li>
                                    <li>Стратегическое партнерство: проект публично обьявляет о партнерских отношениях с крупными компаниями которые подписали соглашения о сотрудничестве после бета периода использования продуктов Vectority.</li>
                                    <li>Листинг на биржай: проект запускается на биржах OKX, BitStamp и BitFinex.</li>
                                </ul>
                            </div>
                            <div className="text-item text-item4">
                                <h6> Квартал 2</h6>
                                <ul>
                                    <li>Популяризация продукта: повышение маркетинговых усилий, для стимуляции роста колличества пользователей. Кампании по привлечению пользователей, реферальные программы и запуск целевой информационно-пропагандистской деятельности с целью привлечения разнообразной пользовательской базы.</li>
                                    <li>Совершенствование искусственного интеллекта: команда разработчиков фокусируется на совершенствовании алгоритмов ИИ, повышении точности генерации контента, параметров настройки и расширении поддерживаемых форматов контента.</li>
                                    <li>Управление сообществом: владельцам токенов предоставлена возможность участвовать в управление платформой посредством процессов голосования для принятия решений в формировании будущего платформы.</li>
                                    <li>Листинг на биржах: проект запускается на биржах Coinbase и Kraken.</li>
                                </ul>
                            </div>
                            <div className="text-item text-item5">
                                <h6> Квартал 3</h6>
                                <ul>
                                    <li>Расширение функций: на основе отзывов пользователей и рыночных требований - на платформе вводится дополнительный функционал. Это может включать дополнительные дорматы контента, инструменты взаимодействия или интеграции с другими платформами.</li>
                                    <li>Международное расширение: запускаются процессы по расширению присутствия платформы на международных рынках, нацеленные на регионы с высоким потенциалом вовлеченности и потребления.</li>
                                    <li>Сжигание запасов токенов из резервов компании: механизмы сжигания токенов реализуются для уменьшения инфляции токенов, регулировки спроса и потенциального увеличения ценность оставшихся токенов.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="roadmap-set  d-none inner-add-text-mobile">
                        <div className="inner-add-text">
                            <div className="text-item">
                                <div className="upper-item">
                                    <h6>[ 2023 ]</h6>
                                    <img src="\roadmap-mobile-line.svg" alt="img" className='img-fluid' />
                                </div>
                                <img src="\roadmapchecked.svg" alt="img" className='img-fluid checked-img' />
                                <h6>Квартал 3 </h6>
                                <ul> 
                                    <li>Запуск проекта: официальный запуск проекта Vectority.ai, платформы по генерации контента, основанной на ИИ технологиях.</li>
                                    <li>Генерация и распределение токенов: старт приватных и публичных раундов предварительной продажи токенов, Распределение между крупными партнерами-инвесторами и независимыми членами сообщества.</li>
                                    <li>Тестовая интеграция в бизнес процессы партнеров: Старт приватного бета периода работы алгоритмов Vectority в ведущих компаниях СНГ и Запада.</li>
                                    <li>Запуск платформы: команда разработчиков фокусируется на совершенствовании основных функций платформы, включая алгоритмы генерации контента на основе искусственного интеллекта, пользовательский интерфейс, функционирование экосистемы проэкта и механизмы интеграции продукта в бизнес процессы.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="inner-add-text">
                            <div className="text-item">
                                <div className="upper-item">
                                    <h6>[ 2023 ]</h6>
                                    <img src="\roadmap-mobile-line.svg" alt="img" className='img-fluid' />
                                </div>
                                <img src="\roadmapchecked.svg" alt="img" className='img-fluid checked-img' />
                                <h6>Квартал 4</h6>
                                <ul>
                                    <li>Бета версия платформы в открытом доступе: бета версия платформы запускается, приглашая ограниченное количество пользователей для предоставления обратной связи и проверки возможностей платформы в масштабах комьюнити.</li>
                                    <li>Развитие сообщества: акцент делается на развитие сообщества с помощью маркетинга и кампаний по повышению осведомленности, взаимодействия с потенциальными пользователями и адаптации процесса персонализированного контентмейкинга.</li>
                                    <li>Интерактивные улучшения: на основе результатов и отзывов пользователей и тестирования. Команда разработчиков совершенствует на функции платформы, улучшая пользовательский опыт работы с продуктом.</li>
                                    <li>Листинг на биржах: Vectority выходит на рынок на Binance, Kucoin и Crypto.com.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="inner-add-text">
                            <div className="text-item">
                                <div className="upper-item">
                                    <h6>[ 2024 ]</h6>
                                    <img src="\roadmap-mobile-line.svg" alt="img" className='img-fluid' />
                                </div>
                                <img src="\roadmapchecked.svg" alt="img" className='img-fluid checked-img' />
                                <h6>Квартал 1</h6>
                                <ul>
                                    <li>Публичный запуск платформы: платформа доступна для широкой пользовательской базы. Пользователи могут начать создавать и генерировать контент, используя доступные инструменты и функции ИИ.</li>
                                    <li>Система бонусов для пользователей: внедрение токеновых вознаграждений пользователей для стимулирования высококачественноого производства и взаимодействия.</li>
                                    <li>Стратегическое партнерство: проект публично обьявляет о партнерских отношениях с крупными компаниями которые подписали соглашения о сотрудничестве после бета периода использования продуктов Vectority.</li>
                                    <li>Листинг на биржай: проект запускается на биржах OKX, BitStamp и BitFinex.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="inner-add-text">
                            <div className="text-item">
                                <div className="upper-item">
                                    <h6 style={{color: "#fff"}}>[ 2024 ]</h6>
                                    <img src="\roadmap-mobile-line.svg" alt="img" className='img-fluid' />
                                </div>
                                <img src="\not-checked.svg" alt="img" className='img-fluid checked-img' />
                                <h6>Квартал 2</h6>
                                <ul>
                                    <li>Популяризация продукта: повышение маркетинговых усилий, для стимуляции роста колличества пользователей. Кампании по привлечению пользователей, реферальные программы и запуск целевой информационно-пропагандистской деятельности с целью привлечения разнообразной пользовательской базы.</li>
                                    <li>Совершенствование искусственного интеллекта: команда разработчиков фокусируется на совершенствовании алгоритмов ИИ, повышении точности генерации контента, параметров настройки и расширении поддерживаемых форматов контента.</li>
                                    <li>Управление сообществом: владельцам токенов предоставлена возможность участвовать в управление платформой посредством процессов голосования для принятия решений в формировании будущего платформы.</li>
                                    <li>Листинг на биржах: проект запускается на биржах Coinbase и Kraken.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="inner-add-text">
                            <div className="text-item">
                                <div className="upper-item">
                                    <h6 style={{color: "#fff"}}>[ 2024 ]</h6>
                                    <img src="\roadmap-mobile-line.svg" alt="img" className='img-fluid' />
                                </div>
                                <img src="\not-checked.svg" alt="img" className='img-fluid checked-img' />
                                <h6>Квартал 3</h6>
                                <ul>
                                    <li>Расширение функций: на основе отзывов пользователей и рыночных требований - на платформе вводится дополнительный функционал. Это может включать дополнительные дорматы контента, инструменты взаимодействия или интеграции с другими платформами.</li>
                                    <li>Международное расширение: запускаются процессы по расширению присутствия платформы на международных рынках, нацеленные на регионы с высоким потенциалом вовлеченности и потребления.</li>
                                    <li>Сжигание запасов токенов из резервов компании: механизмы сжигания токенов реализуются для уменьшения инфляции токенов, регулировки спроса и потенциального увеличения ценность оставшихся токенов.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Roadmap;
